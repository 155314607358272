/* Component Dependencies */
var cardLinksTemplate = require('templates/cardLinks.hbs');
var cardLinksHorizTemplate = require('templates/cardLinks-horiz.hbs');
var footerSocialLinksTemplate = require('templates/cardLinks-footerSocialLinks.hbs');
var iconLinkListTemplate = require('templates/cardLinks-iconLinkList.hbs');
var AriesComponent = require('libs/aries-component');
var Popup = require('libs/popup');

AriesComponent.create({
  type: 'cardLinks',
  template: {
    'cardLinks': cardLinksTemplate,
    'cardLinks-footerSocialLinks': footerSocialLinksTemplate,
    'cardLinks-iconLinkList': iconLinkListTemplate,
    'cardLinks-horiz': cardLinksHorizTemplate 	
   },
  events: {
    'click .js-link-horiz a' : 'showVisited'
  },
  
  showVisited : function(e) {
  	if(this.$el.find(e.target).not('.t-visited')){
		this.$el.find(e.target).addClass('t-visited');
	}	
  }	   
});
